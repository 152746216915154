import React, { useState, useEffect, useContext } from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
// own hooks
import useFetch from '../../../../hooks/useFetch'
// own components
import SelectCustom from '../../../../components/select'
import ButtonGoBack from '../../../../components/buttons/GoBackButton'

// styles
import '../../../../assets/css/charts.scss'
// api
import LineChart from '../../../../components/charts/LineChart'
import { COLORS, MONTHS } from '../../../../constants'
import { downloadFile } from '../../../../helpers'

import { GeneralContext } from '../../../../contexts/GeneralContext'
import { Controller, useForm } from 'react-hook-form'
import { userRegisterByMonthStatisticsApi } from '../../../../api/pathsForHook/users'
import NoInfo from '../../../../components/noInfo'

const StatisticsUsers = () => {
  const { t } = useTranslation('homeAdmin')
  const { clients } = useContext(GeneralContext)

  const [statisticsData, setStatisticsData] = useState([])
  const [years, setYears] = useState([])
  const [enabledDownload, setEnabledDownload] = useState(true)
  const { loading, methodGet } = useFetch()

  const {
    watch,
    control,
    formState: { errors },
  } = useForm()

  const watchedFields = watch()

  useEffect(() => {
    const tempYears = []
    for (let i = 2016; i <= new Date().getFullYear(); i++) {
      tempYears.push(i)
    }
    setYears(tempYears)
  }, [])

  useEffect(() => {
    if (watchedFields.date_joined_year && watchedFields.date_joined_month) fetchData(watchedFields)
  }, [watchedFields.client, watchedFields.date_joined_year, watchedFields.date_joined_month])

  const fetchData = async (filters) => {
    const { data } = await methodGet(userRegisterByMonthStatisticsApi(), filters)
    if (data) {
      setStatisticsData(data)
    }
  }

  return (
    <Container className="d-flex justify-content-center flex-column">
      <ButtonGoBack />
      <Row className="mt-5 ">
        <Col lg={11}>
          <h1>{t('userStatistics', { ns: 'statistics' })}</h1>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Controller
            name="client"
            control={control}
            defaultValue={''}
            render={({ field: { value, onChange } }) => (
              <SelectCustom
                placeholder={t('forms.client', { ns: 'forms' })}
                label={`${t('forms.client', { ns: 'forms' })} *`}
                handleSelect={onChange}
                value={value}
                options={clients.map((c) => ({ id: c.id, name: c.name }))}
                error={errors.client?.message}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            name="date_joined_year"
            control={control}
            rules={{ required: true }}
            defaultValue={2016}
            render={({ field: { value, onChange } }) => (
              <SelectCustom
                label={`${t('forms.year', { ns: 'forms' })} *`}
                handleSelect={onChange}
                value={value}
                options={years.map((y) => ({ id: y, name: y }))}
                error={errors.year?.message}
              />
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            name="date_joined_month"
            control={control}
            rules={{ required: true }}
            defaultValue={1}
            render={({ field: { value, onChange } }) => (
              <SelectCustom
                label={`${t('forms.month', { ns: 'forms' })} *`}
                handleSelect={onChange}
                value={value}
                options={MONTHS.map((m, i) => ({ id: i + 1, name: m }))}
                error={errors.year?.message}
              />
            )}
          />
        </Col>
        {statisticsData?.length > 0 ? (
          <Col sm={12} lg={6}>
            <LineChart
              download={null}
              enabledDownload={enabledDownload}
              labels={statisticsData.map((item) => item.date_joined__date__day)}
              data={[
                {
                  label: t('users'),
                  data: statisticsData.map((item) => item.count),
                  color: COLORS[0],
                },
              ]}
              title={t('registered30DaysBefore')}
              loading={loading}
            />
          </Col>
        ): (
          <NoInfo />
        )}
      </Row>
    </Container>
  )
}

export default StatisticsUsers
